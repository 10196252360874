<template>
    <swiper-slide class="h-full flex flex-col w-full">

        <div class="h-1 bg-green flex flex-row justify-start items-center rounded-full overflow-hidden mb-auto">

            <!-- <div class="h-1 w-10/12 rounded-full overflow-hidden bg-body">
                <div class="h-full rounded-full" :class="'bg-' + color(percentage)"
                     :style="'width:' + percentage + '%'">
                </div>
            </div>

            <div class="h-full flex-1 flex flex-col justify-center items-center">
                <span class="text-xs font-semibold" :class="'text-'+color(percentage)">{{ percentage }}%</span>
            </div> -->

            <div class="h-full w-1/4" :class="{'bg-good': percentage == 25, 'bg-box': percentage != 25}"></div>
            <div class="h-full w-1/4" :class="{'bg-success': percentage == 50, 'bg-box': percentage != 50}"></div>
            <div class="h-full w-1/4" :class="{'bg-warning': percentage == 75, 'bg-box': percentage != 75}"></div>
            <div class="h-full w-1/4" :class="{'bg-danger': percentage == 100, 'bg-box': percentage != 100}"></div>

        </div>

        <div class="h-32 flex flex-row">
            <div class="h-32 w-32 flex-none">
                <chart-pie :data="sectosChartData" :chartId="'chartId'">
                    <div class=" flex flex-col justify-center items-center">
                        <span class="text-xs font-semibold text-white">{{ sectorPercentage.total.oportunity | numberFormat(0) }}</span>
                        <span class="text-font-gray text-xxs mt-2">{{ sectorPercentage.total.sales | reduceBigNumbers(2) }} €</span>
                    </div>
                </chart-pie>
            </div>
            <div class="h-44 w-full overflow-auto flex flex-col justify-start">
                <div v-for="(value, key, index) in sectorPercentage" :key="index" v-if="key !== 'total'">
                    <Sector :sector="value" :name="key" :color="legendColors[key]" class="mt-2"/>
                </div>
            </div>
        </div>
    </swiper-slide>
</template>

<script>
    const ChartPie = () => import("@/charts/ChartPie")
    const Sector = () => import("@/components/Mobile/Pipeline/Sector");

    export default {
        name: "SliderPercentage",
        components: {
            Sector,
            ChartPie
        },
        props: ['sectorPercentage', 'percentage'],
        computed: {
            sectosChartData() {
                let data = []
                let i = 0
                for (let key in this.sectorPercentage) {
                    if (key !== 'total') {
                        data.push({
                            name: key,
                            y: this.sectorPercentage[key].sales,
                            color: this.catColors[i]
                        })
                        i++
                    }
                }
                return data
            },
            legendColors() { return this.$legendColorsBySector(Object.keys(this.sectorPercentage)) }
        },
        methods:{
            color(percentage){

                switch (percentage) {
                    case 25:
                        return 'good'
                        break;
                    case 50:
                        return 'success'
                        break;
                    case 75:
                        return 'warning'
                        break;
                    case 100:
                        return 'danger'
                        break;
                }

            }
        }
    }
</script>